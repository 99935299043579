import React from "react";
import { Link } from "gatsby";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";

function ColorNavbar({props, theme}) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  if (typeof document !== `undefined`) {
    /*
    * Original author of the code snippet: Rik Schennink
    * https://codepen.io/rikschennink/pen/yZYbwQ
    * */
    const debounce = (fn) => {
      let frame;
      return (...params) => {
        if (frame) {
          cancelAnimationFrame(frame);
        }
        frame = requestAnimationFrame(() => {
          fn(...params);
        });
      }
    };
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY;
    }
    document.addEventListener('scroll', debounce(storeScroll), { passive: true });
    storeScroll();
  }

  return (
    <Navbar
      className={`fixed-top ${theme === 'light' ? "light" : "dark"}`}
      expand="lg"
      id="navbar-main"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand id="navbar-brand" to='/' tag={Link}>
            <span>Antje Abel Architekten</span>
          </NavbarBrand>
          <Nav id="header-nav" className="ml-auto">
            <NavItem>
              <Link to="/projekte">Projekte</Link>
            </NavItem>
            <NavItem>
              <Link to="/buero">Büro</Link>
            </NavItem>
            <NavItem>
              <Link to="/kontakt">Kontakt</Link>
            </NavItem>
          </Nav>
          <button
            className="navbar-toggler ml-auto"
            id="navigation"
            type="button"
            aria-label="Open Navigation Menu"
            onClick={() => {
              if (!document.documentElement.classList.contains("nav-open")) {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(true);
              } else {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(false);
              }
            }}
          >
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
            <span className="navbar-toggler-bar" />
          </button>
        </div>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="nav-container">
            <Nav className="ml-auto" navbar>
              <NavItem className="mobile-only">
                <Link to="/projekte" className="link">Projekte</Link>
              </NavItem>
              <NavItem className="mobile-only">
                <Link to="/buero" className="link">Büro</Link>
              </NavItem>
              <NavItem className="mobile-only">
                <Link to="/kontakt" className="link">Kontakt</Link>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <NavItem className="mobile-only">
                <Link to='/impressum' className="link">Impressum</Link>
              </NavItem>
              <NavItem className="mobile-only">
                <Link to='/datenschutz' className="link">Datenschutz</Link>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <NavItem className="mobile-only">
                <a className="link" href="https://facebook.com/" rel="noreferrer" target="_blank">Facebook</a>
              </NavItem>
              <NavItem className="mobile-only">
                <a className="link" href="https://houzz.com/" rel="noreferrer" target="_blank">Houzz</a>
              </NavItem>
              <NavItem className="mobile-only">
                <a className="link" href="https://instagram.com/" rel="noreferrer" target="_blank">Instagram</a>
              </NavItem>
            </Nav>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ColorNavbar;
